import type { ElSelectOptionType } from '@energielenker/common-component-bundle';
import type { DeviceType } from '@/type';

export const meterDeviceTypes = {
	METER_COMPORT: 'meter_comport',
	METER_E3DC: 'meter_e3dc',
	METER_EXPRESSION: 'meter_expression',
	METER_SMA_HM: 'meter_sma_hm',
	METER_SML: 'meter_sml',
	METER_SUNSPEC: 'meter_sunspec',
} as const;

export const chargingpointDeviceTypes = {
	EVSE_HYPERCHARGER: 'evse_hypercharger',
	EVSE_HYPERCHARGER_50: 'evse_hypercharger_50',
	EVSE_HYPERCHARGER_200: 'evse_hypercharger_200',
	EVSE_EASEE: 'evse_easee',
	EVSE_COMPLEO_MB: 'evse_compleo_mb',
	EVSE_I_CHARGING_BLUEBERRY: 'evse_i_charging_blueberry',
	EVSE_DELTA: 'evse_delta',
	EVSE_TECHNAGON: 'evse_technagon',
	EVSE_SMA_EV_CHARGER: 'evse_sma_ev_charger',
	EVSE_CHARGEPOINT: 'evse_chargepoint',
	EVSE_WALTHER_SYSEVO: 'evse_walther_sysevo',
} as const;

export const meterDeviceURL= {
	[meterDeviceTypes.METER_COMPORT]: 'meter_specific_setting_comports',
	[meterDeviceTypes.METER_E3DC]: 'meter_specific_setting_e3dcs',
	[meterDeviceTypes.METER_EXPRESSION]: 'meter_specific_setting_expressions',
	[meterDeviceTypes.METER_SMA_HM]: 'meter_specific_setting_sma_hms',
	[meterDeviceTypes.METER_SML]: 'meter_specific_setting_smls',
	[meterDeviceTypes.METER_SUNSPEC]: 'meter_specific_setting_sunspecs',
} as Record<DeviceType, string>

export const chargingpointDeviceURL: Record<DeviceType, string> = {
	[chargingpointDeviceTypes.EVSE_HYPERCHARGER]: 'EVSE_HYPERCHARGER',
	[chargingpointDeviceTypes.EVSE_HYPERCHARGER_50]: 'EVSE_HYPERCHARGER_50',
	[chargingpointDeviceTypes.EVSE_HYPERCHARGER_200]: 'EVSE_HYPERCHARGER_200',
	[chargingpointDeviceTypes.EVSE_EASEE]: 'EVSE_EASEE',
	[chargingpointDeviceTypes.EVSE_COMPLEO_MB]: 'EVSE_COMPLEO_MB',
	[chargingpointDeviceTypes.EVSE_I_CHARGING_BLUEBERRY]: 'EVSE_I_CHARGING_BLUEBERRY',
	[chargingpointDeviceTypes.EVSE_DELTA]: 'EVSE_DELTA',
	[chargingpointDeviceTypes.EVSE_TECHNAGON]: 'EVSE_TECHNAGON',
	[chargingpointDeviceTypes.EVSE_SMA_EV_CHARGER]: 'EVSE_SMA_EV_CHARGER',
	[chargingpointDeviceTypes.EVSE_CHARGEPOINT]: 'EVSE_CHARGEPOINT',
	[chargingpointDeviceTypes.EVSE_WALTHER_SYSEVO]: 'EVSE_WALTHER_SYSEVO',
} as Record<DeviceType, string>

const mapDataToElSelectOptions = (deviceCategory: Record<string, string>): ElSelectOptionType[] => {
	return Object.entries(deviceCategory).map(([, value]) => {
		return {
			id: value,
			label: value
		}
	})
};

export const meterDeviceTypeOptions: ElSelectOptionType[] = mapDataToElSelectOptions(meterDeviceTypes);
export const chargingpointDeviceTypeOptions: ElSelectOptionType[] = mapDataToElSelectOptions(chargingpointDeviceTypes);

export function getDeviceNameByDeviceId(deviceId: string, deviceTypes: Record<string,string>): DeviceType | null {
	const dt = Object.values(deviceTypes);

	for (const deviceType of dt) {
		if (deviceId.startsWith(deviceType)) {
			return deviceType as DeviceType;
		}
	}

	return null;
}

