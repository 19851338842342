<script setup lang="ts">
import { type Component, onMounted, onUnmounted } from 'vue';
import { RouterView } from 'vue-router';
import gettext from '@/gettext';
import EnbasHeader from '@/components/header/EnbasHeader.vue';
import EnbasSidebar from '@/components/sidebar/EnbasSidebar.vue';
import { defineAsyncComponent, inject, onErrorCaptured } from 'vue';
import { injectionKeyElToastService, ElLayoutWrapper } from '@energielenker/common-component-bundle';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import { useHemsStore, useSystemTime } from '@/stores';
import { storeToRefs } from 'pinia';

const UPDATE_SYSTEM_TIME = import.meta.env.VITE_SYSTEM_TIME_UPDATE_INTERVAL;
const toastService = inject(injectionKeyElToastService);

// setup hemsStore - Inject toast service as stores shouldn't us inject()
const hemsStore = useHemsStore();
const { toastService: hemsToastService } = storeToRefs(hemsStore);
hemsToastService.value = toastService;

// setup systemTime store - Start interval for refreshing time
const systemTimeStore = useSystemTime();
let fetchInterval: ReturnType<typeof setInterval> | null = null;

onMounted(async () => {
	await hemsStore.init();
	fetchInterval = setInterval(systemTimeStore.refresh, UPDATE_SYSTEM_TIME);
});

onUnmounted(() => {
	if (fetchInterval !== null) {
		clearInterval(fetchInterval);
	}
});
onErrorCaptured((e) => {
	toastService?.error(e.message, { pauseOnMouseover: true, delay: 5000 });
});

const localStorageLanguage = localStorage.getItem('ElEnbasAppLanguage');
if (localStorageLanguage) {
	gettext.current = localStorageLanguage;
}

const isDev = import.meta.env.DEV;
const DevBar = defineAsyncComponent(() => {
	return import('@/components/debug/DevBar.vue');
}) as Component;
</script>

<template>
	<ElLayoutWrapper>
		<template #header>
			<EnbasHeader />
		</template>
		<template #sidebar>
			<EnbasSidebar />
		</template>

		<template #content>
			<div class="home">
				<RouterView />
			</div>
		</template>
	</ElLayoutWrapper>
	<DevBar v-if="isDev" />
	<LoadingOverlay />
</template>

<style lang="scss">
	html body {
		font-family: var(--base-font);
	}

	.content {
		overflow-x: hidden;
	}

	.sidebar {
		height: 100%;
		min-height: 100vh;
	}

	div.header {
		padding-left: 2rem !important;
		padding-right: 2rem !important;
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1rem;

		ol{
			margin: 0;
		}
	}

	@include media-breakpoint-down(lg) {
		#app{
			.layout-wrapper{
				display: block;
				.sidebar{
					height: 82px !important;
					width:100% ;
					position: fixed ;
					bottom:1px;
					min-height: auto;
					z-index: 1000;
					padding:0;
					display: flex;
					align-items: center;
				}
			}
		}
	}
</style>
